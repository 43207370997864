<template>
  <div class="repair-suc-wrapper">
    <div class="suc-icon">
      <i class="iconfont iconcheck-active"></i>
    </div>
    <p class="tip-box">提交成功</p>
    <!-- <div class="qrcode-box">
      <p>扫描下方二维码可以进行验收</p>
      <div class="qrcode-img" id="qrcode">

      </div>
    </div> -->

    <div class="btn-box">
      <button @click="handleBtnClick">稍后再验收</button>
    </div>

  </div>
</template>

<script>
  import QRCode from 'qrcodejs2'
  export default {
    name: 'RepairSuc',
    mounted() {
      const id = this.$route.query.id
      this.$nextTick (function () {
        this.qrcode(id);
      })
    },
    methods: {
      handleBtnClick () {
        this.$router.push({
          path: '/deviceRepair'
        })
      },
      //  生成二维码
      qrcode (id) {

        const link = 'https://h5.device.jiot.link/#/deviceRepair/status?id=3&repairId=' + id
        new QRCode('qrcode', {
          width: 170,
          height: 170,        // 高度
          text: link  // 二维码内容
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../style/mixin";
  .repair-suc-wrapper{
    .suc-icon{
      text-align: center;
      i{
        font-size: 5rem;
        color: #51C513;
      }
    }
    p.tip-box{
      text-align: center;
    }
    .qrcode-box{
      margin-top: 3rem;
      margin-bottom: 1rem;
      p{
        font-size: 0.7rem;
        color: #666666;
        text-align: center;
      }
      .qrcode-img{
        width: 10rem;
        height: 9rem;
        margin: 0.5rem auto;
        background-color: #f1f1f1;
      }
    }
    .btn-box{
      width: 100%;
      margin-top: 2rem;
      text-align: center;
      button{
        height: 2.2rem;
        border-radius: 2rem;
        font-size: 0.8rem;
        // color: white;
        padding: 0 1.5rem;
      }
    }
  }
</style>
